
import axios from "@/plugins/axios.js"

const actions = {
  fetchOrders({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_ORDER', response.data.data.order)
           }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchDeliveries({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-deliveries/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_DELIVERIES', response.data.deliveries)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchMessages({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-messages/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_MESSAGES', response.data.data.messages)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  deleteOrder({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/orders/${itemId}/delete`)
        .then((response) => {
          if(response.data.success){
            commit('DELETE_ORDER', itemId)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
	getBuyerOrders: (state) => (id) => {
    return  state.pageData.data.filter((order) => (order.buyer_id === id));
  },
  getProductOrders: (state) => (id) => {
    return  state.pageData.data.filter((order) => (order.product_id == id));
  },
  totalOrders: (state) => state.pageData.data.length,
}

const mutations = {
  SET_ORDERS(state, data) {
    state.pageData = data
  },
  SET_ORDER(state, order){
  	state.order = order
  },
  DELETE_ORDER(state, itemId){
	const ItemIndex = state.pageData.data.findIndex((item) => item.id == itemId)
    state.pageData.data.splice(ItemIndex, 1)
  },

  /**************** THIS IS FOR DELIVERIES********************/
  SET_ORDER_DELIVERIES(state, deliveries){
    state.order.deliveries = [...deliveries]
  },
  ADD_DELIVERY(state, delivery){
    state.order.deliveries.unshift(delivery)
  },
  UPDATE_DELIVERY(state, delivery){
    const dataIndex = state.order.deliveries.findIndex((a) => a.id == delivery.id);
    Object.assign(state.order.deliveries[dataIndex], delivery);
  },
  DELETE_DELIVERY(state, itemId){
    const itemIndex = state.order.deliveries.findIndex((item) => item.id == itemId)
    state.order.deliveries.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR MESSAGES********************/
  SET_ORDER_MESSAGES(state, messages){
    state.order.messages = [...messages]
  },
  ADD_MESSAGE(state, message){
    state.order.messages.push(message)
  },
  UPDATE_MESSAGE(state, message){
    const dataIndex = state.order.messages.findIndex((a) => a.id == message.id);
    Object.assign(state.order.messages[dataIndex], message);
  },
  DELETE_MESSAGE(state, itemId){
    const itemIndex = state.order.messages.findIndex((item) => item.id == itemId)
      state.order.messages.splice(itemIndex, 1)
  },
}


const state = {
  pageData: {
    data: []
  },
  order: {}
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}


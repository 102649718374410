
import axios from "@/plugins/axios.js"

const actions = {
  addNewUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post("/users/create", user)
      .then((response) => {
          if(response.data.success){
            commit('ADD_USER', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchUsers({ commit, state }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/users?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        if(response.data.success){
          commit('SET_USERS', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchRoles({ commit , state }, force = false) {
    if(!force && state.roles.length) return
    return new Promise((resolve, reject) => {
      axios.get("/users/roles")
        .then((response) => {
          if(response.data.success){
            commit('SET_ROLES', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteUser({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/users/${itemId}/delete`)
        .then((response) => {
          if(response.data.success){
            commit('DELETE_USER', itemId)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {
	totalUsers: (state) => state.pageData.data.length,
	totalRoles: (state) => state.roles.length,
}

const mutations = {
  ADD_USER(state, user) {
    state.pageData.data.unshift(user)
  },
  SET_USERS(state, data) {
    state.pageData = data
  },
  SET_USER(state, user) {
    state.user = user
  },
  UPDATE_USER(state, user) {
    Object.assign(state.user, user);
    if(state.pageData.data.length){
      const dataIndex = state.pageData.data.findIndex((o) => o.id == user.id);
      Object.assign(state.pageData.data[dataIndex], user);
    }
  },
  DELETE_USER(state, itemId) {
    state.user = {}
    const userIndex = state.pageData.data.findIndex((u) => u.id == itemId)
    state.pageData.data.splice(userIndex, 1)
  },

  SET_ROLES(state, roles) {
    state.roles = roles
  },
}


const state = {
  pageData: {
    data: []
  },
  roles: [],
  user: null,
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}


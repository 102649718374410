
import axios from "@/plugins/axios.js"

const actions =  {
  addCategory({ commit }, category) {
    return new Promise((resolve, reject) => {
      axios.post("/categories/create", category)
      .then((response) => {
          if(response.data.success){
            commit('ADD_CATEGORY', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCategories({ commit, state  }, force = false) {
    if(!force && state.categories.length) return
    return new Promise((resolve, reject) => {
      axios.get("/categories")
        .then((response) => {
          if(response.data.success){
            commit('SET_CATEGORIES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchCategory({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/categories/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_CATEGORY', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  }
}


const getters =  {
	totalCategories: (state) => state.categories.length,
}

const  mutations =  {
  ADD_CATEGORY(state, category) {
    state.categories.unshift(category)
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_CATEGORY(state, category){
    state.category = category
  },
  UPDATE_CATEGORY(state, category){
    Object.assign(state.category, category);
    if(state.categories.length){
      const dataIndex = state.categories.findIndex((o) => o.id == category.id);
      Object.assign(state.categories[dataIndex], category);
    }
  },
  DELETE_CATEGORY(state, itemId) {
    const ItemIndex = state.categories.findIndex((item) => item.id == itemId)
    state.categories.splice(ItemIndex, 1)
  },

  /************** FOR DESCRIPTION *****************/
  UPDATE_DESCRIPTION(state, description){
    state.category.description = description
  },  
}

const state = {
  categories: [],
  category: {},
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}



import axios from "@/plugins/axios.js"

const actions = {
  fetchReviews({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/reviews?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_REVIEWS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchReview({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/reviews/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_REVIEW', response.data.data.review)
           }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
}

const mutations = {
  ADD_REVIEW(state, item) {
    state.pageData.data.unshift(item)
  },
  SET_REVIEWS(state, data) {
    state.pageData = data
  },
  SET_REVIEW(state, review){
  	state.review = review
  },
  UPDATE_REVIEW(state, review){
  	Object.assign(state.review, review)
    if(state.pageData.data.length){
      const dataIndex = state.pageData.data.findIndex((i) => i.id == review.id);
      Object.assign(state.pageData.data[dataIndex], review);
    }
  },
  DELETE_REVIEW(state, reviewId){
	const ItemIndex = state.pageData.data.findIndex((item) => item.id == reviewId)
    state.pageData.data.splice(ItemIndex, 1)
  },
}

const state = {
  pageData: {
    data: []
  },
  review: {},
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

